<template>
  <div
    class="header-menu__before"
    v-if="isOpenMenu"
    @click.self="closeMenu"
  >
    <transition name="slide-fade">
      <!-- <div class="headerifactive" ></div> -->
      <div
        class="header-menu"
        :class="{ 'is-opened': isOpenMenu }"
        v-if="!$isMobile()"
      >
        <img
          class="header-menu-close"
          v-if="isOpenMenu"
          @click="closeMenu"
          src="@/assets/img/header-menu/close.svg"
        />
        <div class="container">
          <div class="header-menu__account">
            <div class="header-menu__account__login">
              <div class="header-menu__account__login-block">
                <h2
                  class="header-menu__account__login-title"
                  v-if="!$cookies.get('profile')"
                >
                  {{ $t("header.profileEnter") }}
                </h2>
                <h2
                  class="header-menu__account__login-title"
                  v-else
                >
                  {{ $cookies.get("profile").first_name + " " + $cookies.get("profile").last_name }}
                </h2>
                <p
                  class="header-menu__account__login-subtitle"
                  v-if="!$cookies.get('profile')"
                >
                  {{ $t("header.profileAfter") }}
                </p>
                <p
                  v-else
                  class="header-menu__account__login-subtitle"
                >
                  {{ $t("beInCheck") }}
                </p>
                <ul class="header-menu__account__login-advantages">
                  <li class="header-menu__account__login-advantages__item percent">
                    {{ $t("header.profileBenefits.item1") }}
                  </li>
                  <li class="header-menu__account__login-advantages__item bonus">
                    {{ $t("header.profileBenefits.item2") }}
                  </li>
                  <li class="header-menu__account__login-advantages__item discount">
                    {{ $t("header.profileBenefits.item3") }}
                  </li>
                </ul>
              </div>
              <button
                v-if="!$cookies.get('profile')"
                @click.prevent="closeOpen"
                class="header-menu__account__login-button"
              >
                {{ $t("header.login") }}
              </button>
              <a
                class="header-menu__account__login-disconnect"
                v-else
                @click.prevent="
                  $cookies.remove('token')
                  $cookies.remove('profile')
                  $router.go()
                "
              >
                {{ $t("leave") }}
              </a>
            </div>
          </div>

          <div class="header-menu__links">
            <locale-router-link
              to="/news"
              class="header-menu__links-item"
              >{{ $t("header.news") }}</locale-router-link
            >
            <locale-router-link
              to="/useful-articles"
              class="header-menu__links-item"
              >{{ $t("header.usefulArticles") }}</locale-router-link
            >
            <locale-router-link
              class="header-menu__links-item"
              to="/about"
              >{{ $t("header.about") }}</locale-router-link
            >
            <a
              class="header-menu__links-item"
              :href="ruleFile"
              target="_blank"
              >{{ $t("header.rules") }}</a
            >
          </div>

          <locale-router-link
            to="catalog"
            class="header-menu__buttons"
          >
            <button class="header-menu__buttons-item">{{ $t("header.catalog") }}</button>
          </locale-router-link>
        </div>
      </div>

      <!-- MENU MOBILE -->
      <div
        class="header-menu-mobile"
        v-else
      >
        <!-- MENU MOBILE HEADER -->
        <div class="header-menu-mobile__header">
          <img
            class="header-menu-mobile__header-close"
            @click="closeMenu"
            src="@/assets/img/header-menu/crossmobile.svg"
          />
          <div class="header-menu-mobile__header-items">
            <div
              v-if="selectedCity"
              class="header__address header__settings-item"
              @click="openSelectCity"
            >
              {{ selectedCity.attributes.name.substring(0, 3) }}
            </div>
            <lang-change class="header__settings-item" />
          </div>
        </div>

        <!-- MENU MOBILE PROFILE -->
        <div
          class="header-menu-mobile__profile"
          @click="profileInfoOpen = !profileInfoOpen"
        >
          <div class="header-menu-mobile__profile__title">
            <img
              class="header-menu-mobile__profile__title-img"
              src="@/assets/img/header-menu/nouser.svg"
            />
            <div class="header-menu-mobile__profile__title__profile">
              <h2
                class="header-menu-mobile__profile__title__profile-name"
                v-if="!$cookies.get('profile')"
              >
                {{ $t("header.profileEnter") }}
              </h2>
              <h2
                class="header-menu-mobile__profile__title__profile-name"
                v-else
              >
                {{ $cookies.get("profile").first_name + " " + $cookies.get("profile").last_name }}
              </h2>
              <p
                class="header-menu-mobile__profile__title__profile-subtitle"
                v-if="!$cookies.get('profile')"
              >
                {{ $t("header.profileAfter") }}
              </p>
              <p
                v-else
                class="header-menu-mobile__profile__title__profile-subtitle"
              >
                {{ $t("beInCheck") }}
              </p>
            </div>
            <img
              src="@/assets/img/header-menu/down.svg"
              class="header-menu-mobile__profile__title-down"
            />
          </div>
          <div
            class="header-menu-mobile__profile__content"
            v-if="profileInfoOpen"
          >
            <div :class="{ slideDown: profileInfoOpen }">
              <p v-if="$cookies.get('profile')">{{ $t("nowAvailableYou") }}</p>
              <br />
              <ul class="header-menu-mobile__profile__content-list">
                <li class="header-menu-mobile__profile__content-list-item">
                  <img src="@/assets/img/header-menu/percent.svg" />{{
                    $t("header.profileBenefits.item1")
                  }}
                </li>
                <li class="header-menu-mobile__profile__content-list-item">
                  <img src="@/assets/img/header-menu/bonuses.svg" />
                  {{ $t("header.profileBenefits.item2") }}
                </li>
                <li class="header-menu-mobile__profile__content-list-item">
                  <img src="@/assets/img/header-menu/discount.svg" />
                  {{ $t("header.profileBenefits.item3") }}
                </li>
              </ul>
              <button
                @click.prevent="closeOpen"
                v-if="!$cookies.get('profile')"
                class="header-menu-mobile__profile__content-button"
              >
                {{ $t("header.login") }}
              </button>
            </div>
            <a
              class="header-menu__account__login-disconnect"
              v-if="$cookies.get('token')"
              style="margin-left: 40px"
              @click.prevent="
                $cookies.remove('token')
                $cookies.remove('profile')
                $router.go()
              "
            >
              {{ $t("leave") }}
            </a>
          </div>
        </div>

        <!-- MENU MOBILE CATEGORIES -->
        <div class="header-menu-mobile__accordions">
          <div
            class="header-menu-mobile-accordion"
            v-for="(category, index) in menu"
            :key="index"
          >
            <h2
              class="header-menu-mobile-accordion-title"
              v-if="category.data.length"
              @click="category.isOpen = !category.isOpen"
            >
              {{ category.category.label }}
              <img
                src="@/assets/img/header-menu/down.svg"
                :class="{ rotata: category.isOpen }"
              />
            </h2>
            <div
              class="header-menu-mobile-accordion__next"
              v-if="category.data.length && category.isOpen"
            >
              <div
                v-for="(list, index) in category.data"
                :key="index"
              >
                <h2
                  class="header-menu-mobile-accordion__next-title"
                  @click="list.isOpen = !list.isOpen"
                  v-if="list.data.length"
                >
                  {{ $t(list.label) }}
                  <img
                    src="@/assets/img/header-menu/down.svg"
                    :class="{ rotata: list.isOpen }"
                  />
                </h2>
                <div
                  class="header-menu-mobile-accordion__next__last"
                  v-if="list.data.length && list.isOpen"
                >
                  <locale-router-link
                    tag="h2"
                    :to="`catalog?category=${category.category.id}&${getQuery(item)}`"
                    v-for="(item, index) in list.data"
                    :key="index"
                  >
                    {{ item.label ? item.label : item }}
                  </locale-router-link>
                </div>
              </div>
              <locale-router-link
                tag="p"
                :to="`catalog?category=${category.category.id}`"
              >
                Все
              </locale-router-link>
            </div>
            <div v-else-if="category.data.length === 0 && category.isOpen">
              <h2 class="header-menu-mobile-accordion__isempty">В этой категории пусто</h2>
            </div>
          </div>
        </div>

        <!-- MENU MOBILE LINKS -->
        <div class="header-menu-mobile__links">
          <locale-router-link
            class="header-menu-mobile__links-link"
            to="catalog"
            style="color: #12b1eb"
            >{{ $t("header.discounts") }}</locale-router-link
          >
          <locale-router-link
            class="header-menu-mobile__links-link"
            to="stocks"
            style="color: #ff594b"
            >{{ $t("header.stocks") }}</locale-router-link
          >
          <a
            class="header-menu-mobile__links-link"
            v-if="!$cookies.get('profile')"
            @click="getIsOpenHiddenModal(true)"
          >
            {{ $t("header.heatingSystems") }}
          </a>
          <locale-router-link
            v-else
            class="header-menu-mobile__links-link"
            to="heating-systems/bat"
            >{{ $t("header.heatingSystems") }}</locale-router-link
          >

          <locale-router-link
            class="header-menu-mobile__links-link"
            to="recipes"
            >{{ $t("header.coctails") }}</locale-router-link
          >
          <locale-router-link
            to="useful-articles"
            class="header-menu-mobile__links-link"
            >{{ $t("footer.usefulArticles") }}</locale-router-link
          >
          <locale-router-link
            class="header-menu-mobile__links-link"
            to="news"
            >{{ $t("footer.news") }}</locale-router-link
          >
          <locale-router-link
            class="header-menu-mobile__links-link"
            to="brands"
            >{{ $t("header.brands") }}</locale-router-link
          >
          <locale-router-link
            v-if="!$isMobile()"
            to="catalog"
            style="margin: 0"
            class="header-menu__buttons"
          >
            <button class="header-menu__buttons-item">{{ $t("header.catalog") }}</button>
          </locale-router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { actionTypes } from "@/store"
import LocaleRouterLink from "../language/LocaleRouterLink.vue"
export default {
  components: {
    LocaleRouterLink,
    LangChange: () => import("@/components/language/LangChange.vue"),
  },
  name: "AppMenu",
  props: {
    ruleFile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      social: null,
      profileInfoOpen: false,
      menu: [],
      menuAttributes: [
        {
          type: "countries",
          value: "country",
          data: [],
        },
        {
          type: "sugar",
          value: "sugar",
          data: [],
        },
        {
          type: "volume",
          value: "volume",
          data: [],
        },
        {
          type: "strenght",
          value: "strenght",
          data: [],
        },
        {
          type: "vinePlace",
          value: "vine_place",
          data: [],
        },
      ],
    }
  },
  mounted() {},
  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
    ...mapState("categoriesModule", ["categoriesFiltered", "categoriesProducts"]),
  },
  methods: {
    ...mapActions({
      closeMenu: actionTypes.changeIsOpenMenu,
      openMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
      openIsAuthActive: actionTypes.changeIsAuthActive,
      getIsOpenHiddenModal: actionTypes.getIsOpenHiddenModal,
    }),
    closeOpen() {
      this.closeMenu()
      this.openIsAuthActive(true)
    },
    getQuery(item) {
      if (item?.id) {
        return `&view=${item.id}`
      } else {
        return `&label=${item}`
      }
    },
  },
  watch: {
    "$route.path": {
      deep: true,
      handler() {
        if (this.isOpenMenu) {
          this.closeMenu()
        }
      },
    },
    isOpenMenu: {
      handler() {
        if (this.isOpenMenu) {
          this.menu = []
          this.categoriesFiltered.forEach((category) => {
            this.categoriesProducts
              .find((e) => e.id === category.id)
              .data.forEach((products) => {
                this.menuAttributes.forEach((atr) => {
                  atr.data.push(products.attributes[atr.value])
                })
              })

            // every category has his own menu
            this.menu.push({
              category: category,
              isOpen: false,
              data: [
                {
                  type: "view",
                  label: "attributes.view",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "countries",
                  label: "attributes.country",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "sugar",
                  label: "attributes.sugar",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "volume",
                  label: "attributes.volume",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "strenght",
                  label: "attributes.fortress",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "vinePlace",
                  label: "attributes.grape",
                  data: [],
                  isOpen: false,
                },
              ],
            })

            // sort attributes by their types
            this.menuAttributes.forEach((atr) => {
              this.menu
                .find((menu) => {
                  return menu.category.id === category.id
                })
                .data.find((e) => e.type === atr.type).data = Array.from(
                new Set(atr.data.filter((element) => element != null && element != "")),
              )
            })

            // clear menu attributes
            this.menuAttributes.forEach((atr) => {
              atr.data = []
            })

            // special sorting for view typed filter
            category.subcategories.forEach((e) => {
              if (e.products.length) {
                this.menu
                  .find((menu) => menu.category.id === category.id)
                  .data.find((e) => e.type === "view")
                  .data.push({
                    id: e.id,
                    label: e.label,
                  })
              }
            })
          })
        } else {
          this.menuAttributes.forEach((atr) => {
            atr.data = []
          })
        }
      },
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
